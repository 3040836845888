import { Point } from "../utils/Point";
import { WindowManager } from "../utils/WindowManager";
import gsap from "gsap";
import { introPage } from "../templates/IntroPage";
import { isTouch } from "../utils/Helpers";

export const CURSOR_DEFAULT_3D = "default3d";
export const CURSOR_PLUS_3D = "plus3d";
export const CURSOR_MINUS_3D = "minus3d";
export const CURSOR_HOVER_3D = "hover3d";
export const CURSOR_LOADER = "loader";
export const CURSOR_COMINGSOON = "comingsoon";

export class CursorManager {
  public element: HTMLElement;
  private activeCursor: HTMLDivElement;
  private type: string;
  private enabled: boolean;
  private loading: boolean;
  private currentPoint: Point = new Point(0, 0);
  private onMouseMove: any = this._onMouseMove.bind(this);
  private isTouch: boolean = isTouch();

  public constructor(element: HTMLElement) {
    this.element = element;
    addEventListener("mousemove", this.onMouseMove);
  }

  public hide() {
    if (this.isTouch) {
      this.element.style.visibility = "hidden";
    }
  }

  public show() {
    if (this.isTouch) {
      this.element.style.visibility = "visible";
    }
  }

  public enable() {
    this.enabled = true;
  }

  public disable() {
    this.enabled = false;
  }

  public showLoading() {
    if (this.loading || introPage.animatedIn) {
      return;
    }
    this.setType(CURSOR_LOADER);
    this.loading = true;
    gsap.set(this.activeCursor, { rotation: 0 });
    gsap.to(this.activeCursor, 1.5, {
      ease: "none",
      rotation: 360,
      repeat: -1
    });
    //document.body.style.cursor = 'progress';
  }

  public hideLoading() {
    if (!this.loading) {
      return;
    }
    this.loading = false;
    gsap.killTweensOf(this.activeCursor);
    this.setType();
    //document.body.style.cursor = 'auto';
  }

  public setType(type: string = null) {
    if (this.loading) {
      return;
    }

    this.type = type;

    if (this.activeCursor) {
      this.activeCursor.style.display = "none";
    }

    if (type) {
      this.element.style.display = "block";
      this.activeCursor = this.getCursor(type);
      this.activeCursor.style.display = "block";
      document.body.style.cursor = "none";
    } else {
      this.element.style.display = "none";
      document.body.style.cursor = "auto";
    }

    this.move(this.currentPoint);
  }

  public getType(): string {
    return this.type;
  }

  public getCursor(type): HTMLDivElement {
    return this.element.querySelector(".cursor." + type);
  }

  private _onMouseMove(event) {
    this.move(new Point(event.clientX, event.clientY));
  }

  public move(point: Point) {
    let w = 0;
    let h = 0;

    if (this.activeCursor) {
      w = this.activeCursor.clientWidth / 2;
      h = this.activeCursor.clientHeight / 2;
    }

    if (this.type) {
      document.body.style.cursor = "none";
    }

    if (this.isTouch) {
      point.x = WindowManager.width / 2;
      point.y = WindowManager.height / 2;
    }

    this.currentPoint = point;
    this.element.style.transform =
      "translate3d(" + (point.x - w) + "px, " + (point.y - h) + "px, 0)";
  }
}

export const cursorManager = new CursorManager(
  document.body.querySelector(".CursorManager")
);
