import { SlidePage } from "./SlidePage";
import { colorizer, THEME_DARK, THEME_LIGHT } from "../modules/Colorizer";
import { LIGHT } from "../utils/Contants";

export class EventsPage extends SlidePage {
  private _eventsContainer: HTMLDivElement;
  private _pastEventsContainer: HTMLDivElement;
  private _events: NodeListOf<HTMLDivElement>;

  constructor(element) {
    super(element);

    this._eventsContainer = element.querySelector(".active");
    this._pastEventsContainer = element.querySelector(".past");
    this._events = this._eventsContainer.querySelectorAll(".event");

    let l = this._events.length;

    for (let i = 0; i < l; i++) {
      if (Boolean(this._events[i].getAttribute("data-pastEvent")) === true) {
        this._pastEventsContainer.appendChild(this._events[i]);
        this._pastEventsContainer.style.display = "grid";
      }
    }
  }

  public animateIn() {
    colorizer.changeColor(this.textColor == LIGHT ? THEME_LIGHT : THEME_DARK);
    return super.animateIn();
  }

  public animateOut() {
    colorizer.changeColorBack();
    return super.animateOut();
  }
}
