import {
  Color,
  LinearFilter,
  MeshBasicMaterial,
  NearestFilter,
  Texture,
  VideoTexture
} from "three";
import * as THREE from "three";
import { renderer } from "./Gallery3d";
import { VIDEO_URL_MAP } from "../../utils/Contants";
import { state } from "../../Main";
import Konva from "konva";

export class MediaMaterial extends MeshBasicMaterial {
  public videoElement: HTMLVideoElement;
  public videoLoaded: boolean;
  private videoPlaying: boolean = false;
  private url: string;
  private videoSrcElement: HTMLSourceElement;
  private videoUrl: string;
  private videoTexture: Texture;
  public autoplayVideo: boolean;

  constructor(params = null) {
    super(params);
  }

  public loadImage(
    imageUrl,
    flipY: boolean = true,
    comingSoon: boolean = false
  ) {
    if (imageUrl == this.url) {
      return;
    }

    this.url = imageUrl;
    this.needsUpdate = true;
    return new Promise(resolve => {
      const texture = new THREE.TextureLoader().load(imageUrl, image => {
        // Check for Coming Soon
        if (comingSoon) {
          const container: HTMLDivElement = document.createElement("div");
          const imgWidth = image.image.width;
          const imgHeight = image.image.height;

          const layer = new Konva.Layer();
          const KonvaConfig: Konva.StageConfig = {
            container: container,
            width: imgWidth,
            height: imgHeight
          };

          const stage = new Konva.Stage(KonvaConfig);
          const img = new Konva.Image({
            x: 0,
            y: 0,
            image: image.image,
            width: imgWidth,
            height: imgHeight,
            brightness: -0.25,
            contrast: -25
          });

          stage.add(layer);
          img.cache();
          img.filters([
            Konva.Filters.Grayscale,
            Konva.Filters.Contrast,
            Konva.Filters.Brighten
          ]);
          layer.add(img);

          layer.on("draw", () => {
            const canvas1: HTMLCanvasElement = container.querySelector(
              "canvas"
            );
            const ctx1: CanvasRenderingContext2D = canvas1.getContext("2d");
            const imageData = ctx1.getImageData(
              0,
              0,
              imgWidth - 1,
              imgHeight - 1
            );
            image.image = imageData;
            renderer.initTexture(texture);
            resolve();
          });
        } else {
          renderer.initTexture(texture);
          resolve();
        }
      });
      texture.flipY = flipY;
      this.map = texture;
      this.map.anisotropy = renderer.capabilities.getMaxAnisotropy();
    });
  }

  public loadVideo(
    videoUrl,
    flipY: boolean = true,
    comingSoon: boolean = false
  ) {
    if (videoUrl == this.url) {
      return;
    }

    this.url = videoUrl.replace("&amp;", "&");
    this.color = new Color();
    this.videoUrl = videoUrl.replace("&amp;", "&");
    this.setupVideoElement();
    this.videoTexture = new Texture(this.videoElement);
    this.map = this.videoTexture;
    //Todo: correct in model
    this.map.flipY = flipY;

    return new Promise(resolve => {
      this.videoElement.addEventListener("loadeddata", e => {
        if (this.videoElement.readyState >= 1) {
          this.onVideoLoad();
          resolve();
        }
      });

      this.videoElement.addEventListener("error", e => {
        console.log("error", this.videoElement.error);
        resolve();
      });

      this.fetchVideoUrl();
    });
  }

  private setupVideoElement() {
    this.videoSrcElement = document.createElement(
      "source"
    ) as HTMLSourceElement;
    this.videoSrcElement.type = "video/mp4";
    this.videoElement = document.createElement("video") as HTMLVideoElement;
    this.videoElement.muted = true;
    this.videoElement.preload = "metadata";
    this.videoElement.setAttribute("playsinline", "");
    this.videoElement.setAttribute("loop", "");
    this.videoElement.loop = true;
    this.videoElement.crossOrigin = "anonymous";
    this.videoElement.appendChild(this.videoSrcElement);
  }

  public async fetchVideoUrl() {
    const map = state.getValue(VIDEO_URL_MAP);
    if (this.videoElement) {
      if (map[this.videoUrl]) {
        this.videoSrcElement.src = map[this.videoUrl];
        this.videoElement.load();
      } else {
        await fetch(this.videoUrl, {
          method: "HEAD"
        }).then(response => {
          const url = response.url + "#t=0.1";
          this.videoSrcElement.src = url;
          map[this.videoUrl] = url;
          this.videoElement.load();
        });
      }
    }
  }

  private onVideoLoad() {
    this.color = new Color(0xffffff);
    this.videoTexture.minFilter = THREE.LinearFilter;
    this.videoTexture.magFilter = THREE.LinearFilter;
    this.videoTexture.format = THREE.RGBFormat;
    this.videoTexture.generateMipmaps = false;
    this.needsUpdate = true;
    this.videoLoaded = true;
    this.render(true);
    if (this.autoplayVideo) {
      this.playVideo();
    }
  }

  public playVideo() {
    if (this.videoElement && this.videoLoaded && !this.videoPlaying) {
      this.videoPlaying = true;
      this.videoElement.play();
    } else {
      this.autoplayVideo = true;
    }
  }

  public pauseVideo() {
    this.autoplayVideo = false;
    if (this.videoElement && this.videoLoaded && this.videoPlaying) {
      this.videoPlaying = false;
      this.videoElement.pause();
    }
  }

  public render(force = false) {
    if (this.videoTexture && this.videoLoaded && (this.videoPlaying || force)) {
      this.videoTexture.needsUpdate = true;
    }
  }

  public clear() {
    this.url = null;
    this.videoLoaded = false;

    if (this.videoTexture) {
      this.videoTexture.dispose();
    }
    if (this.map) {
      this.map.dispose();
      this.map = null;
    }
    if (this.videoElement) {
      this.pauseVideo();
      this.videoUrl = null;
      if (this.videoSrcElement) {
        this.videoSrcElement.setAttribute("src", "");
      }
      this.videoElement.load();
    }
    this.dispose();
  }
}
